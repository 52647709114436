<template>
  <div class="page" v-loading="loading">
    <el-form
      class="save-content"
      label-width="100px"
      :model="form"
      :rules="rules"
      ref="saveForm"
    >
      <el-form-item label="权益展示：">
        <el-radio-group v-model="form.enable">
          <el-radio :label="1">开启</el-radio>
          <el-radio :label="0">关闭</el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item label="展示标签：">
        <el-button size="small" @click="AddTagsDialogShow = true"  type="primary">新增标签</el-button>
        <el-checkbox-group v-model="tags">
          <el-checkbox
            v-for="(item,index) in form.tags"
            :key="item.name"
            :label="item.name"
            :name="item.name"
          >{{ item.name }}<i v-if="!item.is_default" @click="form.tags.splice(index,1)" style="margin-left: 10px;" class="el-icon-close"></i></el-checkbox>
        </el-checkbox-group>
      </el-form-item>
    </el-form>
    <FixedActionBar>
      <el-button  @click="cancel">取消</el-button>
      <el-button @click="$emit('openCode')">预览</el-button>
      <el-button type="primary" @click="saveConfig">保存</el-button>
    </FixedActionBar>
    <AddTagsDialog :openDialog.sync="AddTagsDialogShow" @submit="submit"/>
  </div>
</template>

<script>
import FixedActionBar from '@/base/layout/FixedActionBar'
import AddTagsDialog from '../../components/CycIntroducePage/AddTagsDialog'
import CycIntroducePage from '../../mixins/CycIntroducePage'
export default {
  mixins: [CycIntroducePage],
  components: { FixedActionBar,
    AddTagsDialog
  },
  model: {
    prop: "previewForm",
    event: "setPreviewForm",
  },
  props: {
    previewForm:Object,
    formData: Object,
  },
  data() {
    return {
      key:'guide',
      loading: true,
      rules: {},
      AddTagsDialogShow:false,
    }
  },
  computed: {
    tags: {
      get() {
        return this.form.tags?.filter((item) => item.is_show).map((item) => item.name)
      },
      set(val) {
        this.form.tags = this.form.tags.map((item) => {
          if (val.includes(item.name)) {
            item.is_show = 1
          } else {
            item.is_show = 0
          }
          return item
        })
      },
    },
    form:{
      get(){
        console.log(this.$props);
        return this.$props.previewForm[this.key]
      },
      set(val){
        let formData = {...this.$props.previewForm}
        formData[this.key] = val
        this.$emit('setPreviewForm',formData)
      }
    }
  },
  methods: {
    saveConfig() {
      this.$refs.saveForm.validate((valid) => {
        if (valid) {
          this.loading = true
          this.$emit('saveConfig', { key: this.key, config: this.form })
        } else {
          setTimeout(() => {
            this.$nextTick(() => {
              let isError = document.getElementsByClassName('is-error')
              console.log('isError', isError)
              isError[0].scrollIntoView({ block: 'center' })
            })
          }, 100)
          this.$message.error('请填写完整信息')
          return false
        }
      })
    },
    submit(name){
        this.AddTagsDialogShow = false
        if(this.form.tags.findIndex(item => item.name === name) === -1){
          this.form.tags.push({name,is_show:1,is_default:0})
        }else{
          this.$message.error('标签已存在')
        }
    },
    cancel(){
      this.form = JSON.parse(JSON.stringify(this.$props.formData[this.key]))
    },
  },
  watch: {
    '$props.formData.guide': {
      handler(val) {
        this.form = JSON.parse(JSON.stringify(val))
        this.loading = false
      },
      deep: true,
      immediate: true,
    },
  },
  mounted() {},
}
</script>

<style></style>
