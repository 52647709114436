<template>
  <el-dialog
    title="添加标签"
    :visible.sync="openDialog"
    width="500px"
    :show-close="true"
    class="dialog"
    @close="cancel"
  >
    <el-form
      size="medium"
      :model="form"
      ref="fromName"
      label-width="95px"
      @submit.native.prevent
    >
      <el-form-item
        label="标签名称："
        prop="name"
        :rules="{
          required: true,
          message: '标签名称不能为空',
          trigger: 'blur',
        }"
      >
        <el-input
          type="text"
          style="width: 75%"
          v-model="form.name"
          placeholder="请输入标签名称"
        ></el-input>
      </el-form-item>
    </el-form>
    <span slot="footer">
      <el-button @click="cancel">取消</el-button>
      <el-button type="primary" @click="submit">确定</el-button>
    </span>
  </el-dialog>
</template>

<script>
export default {
  props: {
    openDialog: Boolean,
  },
  computed: {
    open: {
      get() {
        return this.openDialog
      },
      set(val) {
        this.$emit('update:openDialog', val)
      },
    },
  },
  data() {
    return {
      form: {
        name: '',
      },
    }
  },
  methods: {
    cancel(){
        this.$nextTick(() => {
          this.$refs.fromName.clearValidate()
        })
        this.form.name = ''
        this.open = false
    },
    submit() {
      this.$refs.fromName.validate(async (valid) => {
        if (valid) {
          this.$emit('submit', this.form.name)
        }
      })
    },
  },
}
</script>

<style></style>
